export class RentRoomDataRequest {
    public id: number = 0;
    public name?: string = '';
    // tslint:disable-next-line:variable-name
    public code: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_room_type_id: number = 0;
    // tslint:disable-next-line:variable-name
    public volume: number = 0;
    // tslint:disable-next-line:variable-name
    public area: number = 0;
    // tslint:disable-next-line:variable-name
    public storey: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_id: number = 0;
    public x: number = 0;
    public y: number = 0;
}
