var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('items-table',{attrs:{"headers":_vm.headers,"props":_vm.props,"fetch-action":_vm.fetchAction,"mutation-types":_vm.mutationTypes,"remove-action":_vm.removeAction,"parent-id":_vm.rentId,"filterConfig":_vm.filters},scopedSlots:_vm._u([{key:"name",fn:function({ item }){return [_c('a',{on:{"click":function($event){_vm.goToItem(
                    'rent-document-details',
                    {
                        rentId: String(item.rent_id),
                        id: String(item.id),
                    },
                    item,
                )}}},[_vm._v(" "+_vm._s(item ? item.name : ''))])]}},{key:"item.status",fn:function({ item }){return [(item.status)?_c('chip-color',{attrs:{"color":_vm.getStatus(item.status).color,"small":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status).name)+" ")]):_vm._e()]}},{key:"item.revision_status",fn:function({ item }){return [(item.revision_status)?_c('chip-color',{attrs:{"color":_vm.getRevisionStatus(item.revision_status).color,"small":""}},[_vm._v(" "+_vm._s(_vm.getRevisionStatus(item.revision_status).name)+" ")]):_c('v-chip',{attrs:{"small":"","outlined":""}},[_vm._v(" brak ")])]}},{key:"item.category",fn:function({ item }){return [_c('chip-color',{attrs:{"color":item.category.color,"small":""}},[_vm._v(" "+_vm._s(item.category.name)+" ")])]}},{key:"item.due_date",fn:function({ item }){return [(item.due_date)?_c('span',[_vm._v(_vm._s(_vm.parseDate(item.due_date)))]):_c('span',[_vm._v("-")])]}},{key:"actions",fn:function({item}){return [_c('tooltip-btn',{attrs:{"tooltipDisabled":_vm.isPermitted(`delete`),"tooltipText":" Brak uprawnień","icon":"","disabled":!_vm.isPermitted('delete')},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{staticClass:"pa-2",attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"form",fn:function(){return [_c('rent-document-form',{key:_vm.formDialog,attrs:{"item-data":_vm.editedItem,"edit":_vm.edit,"show":_vm.formDialog},on:{"saved":function($event){return _vm.itemSaved()},"canceled":function($event){return _vm.closeForm()},"next":function($event){return _vm.addNew()}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }