import {Rent} from '@/modules/rents/shared/models/rent';
import { Renter } from '@/modules/renters/shared/models/renter';
export class RentDataRequest {
    public id: number = 0;
    public name?: string = '';
    public code?: string = '';
    // tslint:disable-next-line:variable-name
    public rent_type_id: number = 0;
    // tslint:disable-next-line:variable-name
    public status: string = '';
    // tslint:disable-next-line:variable-name
    public price: number = 0;
    // tslint:disable-next-line:variable-name
    public description: string = '';
    // tslint:disable-next-line:variable-name
    public media_available: string[] = [];

    public address: string = '';
    public renters: Renter[] = [];
    // tslint:disable-next-line:variable-name
    public number_of_storeys: string = '';
    // tslint:disable-next-line:variable-name
    public start_date?: string = '';
    // tslint:disable-next-line:variable-name
    public end_date?: string = '';

    // tslint:disable-next-line:variable-name
    public association_fee?: number = 0;
    // tslint:disable-next-line:variable-name
    public utilities_costs?: number = 0;
    // tslint:disable-next-line:variable-name
    public garbage_costs?: number = 0;
    // tslint:disable-next-line:variable-name
    public other_costs?: number = 0;

}
