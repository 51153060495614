
import { Component, Watch , Prop} from 'vue-property-decorator';
import SelectMediaList from '@/modules/media/components/select.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import { RentMediaFile } from '@/modules/rents/shared/models/rent-media-file';
import { ModuleState } from '@/shared/state/template/module-state';
import { RentMediaFileDataRequest } from '@/modules/rents/shared/requests/rent-media-file-data-request';
import { ModuleProps } from '@/shared/state/template/module-props';
import { rentMediaFilesModule } from '@/modules/rents/shared/state/state';
import { getIcon } from '@/modules/media/shared/helpers';
import RentMediaCarousel from '@/modules/rents/components/details/media/carousel.vue';
import { MediaFileSelect } from '@/modules/media/shared/models/media-file-select';
import { FetchParams } from '@/shared/types';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        SelectMediaList,
        RentMediaCarousel,
    },
})
export default class RentMedia extends ListComponent<
    RentMediaFile,
    RentMediaFileDataRequest
> {
    @Prop(Number) public rentId!: number;
    @Prop(Boolean) public hideAddButton!: boolean;
    public props: ModuleProps = rentMediaFilesModule.moduleProps;
    public actionsTypes = rentMediaFilesModule.actionsTypes;
    public mutationTypes = rentMediaFilesModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers: object[] = [];
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store: ModuleState<RentMediaFile, RentMediaFileDataRequest> =
        this.$store.state.rentsState.rentMediaFilesState;
    public mediaSelectDialog: boolean = false;
    public mediaDialog: boolean = false;
    public dialogId: number = 0;
    public getIcon = getIcon;
    public subjectId!: number;

    public created() {
        this.id = this.rentId;
        this.$store.dispatch(this.actionsTypes.FETCH_DATA, {id: this.id, simple: true});
        this.fetchData();
    }

    public pageChange(page: number) {
        this.page = page;
    }

    public openDetails(index: number) {
        this.dialogId = index;
        this.mediaDialog = true;
    }

    public addMedia(items: number[]) {
        this.mediaSelectDialog = false;
        const mediaFiles = items.map((e: number) => {
            return { media_file_id: e } as RentMediaFile;
        });
        const rentMediaRequest: RentMediaFileDataRequest = new RentMediaFileDataRequest();
        rentMediaRequest.rent_id = this.id || 0;
        rentMediaRequest.rent_media_files = mediaFiles;
        this.$store.dispatch(this.actionsTypes.STORE_ITEM, rentMediaRequest).then(() => {
            this.page = 1;
            this.$store.dispatch(this.actionsTypes.FETCH_DATA, { id: this.id, simple: true });
            this.fetchParams.per_page = 3;
            this.fetchData();
        });
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`rents.plans.${action}`);
    }

    get itemsList(): MediaFileSelect[] {
        return this.store.dataList.filter((value: any) => value.media_file) as MediaFileSelect[];
    }

    get items() {
        return this.store.data ? this.store.data.filter((item) => item.media_file) : [];
    }

    get columns() {
        switch (this.$vuetify.breakpoint.name) {
            case 'lg':
                return 2;
            case 'xl':
                return 3;
            default:
                return 3;
        }
    }

    get loadersAmount() {
        if (this.page === this.last_page) {
            return this.total - (this.page - 1) * this.columns;
        } else {
            return this.columns;
        }
    }

    @Watch('columns')
    public onColumnsChange(val: number) {
        this.fetchParams.per_page = this.columns;
        if (!this.loading) {
            this.fetchData();
        }
    }

    public parseParams(params: FetchParams) {
        params.per_page = this.columns;
        callSuper(this, 'parseParams', params);
        return params;
    }

    public mounted() {
        this.$watch('meta', this.onLastPageChange, {deep: true});
    }

    public onLastPageChange(val: number) {
        if (this.page > this.last_page) { this.page = val; }
    }
}
