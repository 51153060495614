
    import { Component, Prop, Watch } from 'vue-property-decorator';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import { RentDocumentAttachmentDataRequest } from '@/modules/rents/shared/requests/rent-document-attachment-data-request';
    import { rentDocumentAttachmentsModule } from '@/modules/rents/shared/state/state';
    import MediaFileInput from '@/modules/media/components/media-file-input.vue';
    import DatePicker from '@/shared/components/elements/date-picker.vue';
    import { MediaFile } from '@/modules/media/shared/models/media-file';
    import { RentDocumentAttachment } from '@/modules/rents/shared/models/documents/rent-document-attachment';
    import moment from 'moment';

    @Component({
        components: {
            FormActions,
            MediaFileInput,
            DatePicker,
        },
    })
    export default class RentDocumentAttachmentsForm extends FormComponent<RentDocumentAttachmentDataRequest> {
        @Prop(Number) public rentDocumentId!: number;
        @Prop(Number) public rentId!: number;
        @Prop(Array) public attachments!: RentDocumentAttachment[];
        @Prop(Boolean) public hasRevisions!: boolean;

        public actionsTypes = rentDocumentAttachmentsModule.actionsTypes;
        public store: any = this.$store.state.rentsState.rentDocumentAttachmentsState;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public requestData = new RentDocumentAttachmentDataRequest();

        public mediaFile: MediaFile | null = null;
        public initialDateOfissue: string | null = null;
        public initialDueDate: string | null = null;

        get parsedAttachments() {
            if (!this.edit || !this.itemData.id) {
                return this.attachments;
            }
            return this.attachments.filter((el) => el.id !== this.itemData.id);
        }

        get mediaFileErrorMessages() {
            if (!this.itemData.media_file_id) {
                return [];
            }
            const fileOccupied = this.parsedAttachments.find((el) => el.media_file_id === this.itemData.media_file_id);
            if (fileOccupied) {
                return ['Załączony dokument jest już używany'];
            }
            return [];
        }

        get dueDateErrorMessages() {
            if (!this.itemData.due_date) {
                return [];
            }
            if (
                this.itemData.date_of_issue &&
                moment(this.itemData.due_date).isSameOrBefore(moment(this.itemData.date_of_issue), 'day')
            ) {
                return ['Niepoprawna wartość'];
            }
            return [];
        }
        get dateOfIssueErrorMessages() {
            if (!this.itemData.date_of_issue) {
                return [];
            }
            const dateOccupied = this.parsedAttachments.find((el) =>
                moment(this.itemData.date_of_issue).isSame(el.date_of_issue, 'day'),
            );
            if (dateOccupied) {
                return ['Wpis posiada już dokument o tej samej dacie obiązywania'];
            }
            return [];
        }

        get dueDateText() {
            if (!this.itemData.due_date || this.dueDateErrorMessages.length > 1) {
                return '';
            }
            const diff = moment(this.itemData.due_date).diff(moment.now(), 'day');
            if (diff > 0) {
                return `Pozostało ${moment(this.itemData.due_date).diff(moment.now(), 'day') + 1} dni`;
            } else if (diff === 0) {
                return `Pozostał ${moment(this.itemData.due_date).diff(moment.now(), 'day') + 1} dzień`;
            } else if (diff === -1) {
                return `Mineło 1 dzień temu`;
            } else {
                return `Mineło ${Math.abs(moment(this.itemData.due_date).diff(moment.now(), 'day'))} dni temu`;
            }
        }

        get bannerText() {
            if (this.dateOfIssueErrorMessages.length > 0 || this.dueDateErrorMessages.length > 0) {
                return null;
            }

            const dateOfIssue = this.itemData.date_of_issue ? moment(this.itemData.date_of_issue) : null;
            const dueDate = this.itemData.due_date ? moment(this.itemData.due_date) : null;
            const now = moment.now();

            if (!dateOfIssue) {
                return null;
            }

            const currentAttachment = this.parsedAttachments.find((el) => el.status === 'current');
            if (currentAttachment) {
                if (
                    moment(currentAttachment.date_of_issue).isAfter(dateOfIssue, 'day') &&
                    dateOfIssue.isSameOrBefore(now, 'day')
                ) {
                    return 'Ten załącznik stanie się wygasłym dokumentem!';
                }
            }
            const editedItemIsValidToday =
                dateOfIssue.isSameOrBefore(now, 'day') && (!dueDate || (dueDate && dueDate.isAfter(now, 'day')));
            const editedItemIsExpired =
                dateOfIssue.isSameOrBefore(now, 'day') && (!dueDate || (dueDate && dueDate.isSameOrBefore(now, 'day')));

            if (editedItemIsValidToday) {
                return 'Ten załącznik stanie się obowiązującym dokumentem!';
            } else if (editedItemIsExpired) {
                return 'Ten załącznik już nie obowiązuje!';
            } else {
                return `Ten załącznik stanie się obowiązującym dokumentem za ${dateOfIssue.diff(now, 'days') + 1} ${
                    dateOfIssue.diff(now, 'days') + 1 === 1 ? 'dzień' : 'dni'
                }!`;
            }
        }

        get bannerEditText() {
            if (this.dateOfIssueErrorMessages.length > 0 || this.dueDateErrorMessages.length > 0) {
                return null;
            }

            const dateOfIssue = this.itemData.date_of_issue ? moment(this.itemData.date_of_issue) : null;
            const dueDate = this.itemData.due_date ? moment(this.itemData.due_date) : null;
            const now = moment.now();

            if (!dateOfIssue) {
                return null;
            }

            const editedItemIsValidToday =
                dateOfIssue.isSameOrBefore(now, 'day') && (!dueDate || (dueDate && dueDate.isAfter(now, 'day')));

            const status = this.itemData.status;

            if (!status) {
                return this.bannerText;
            }

            if (status === 'current') {
                const candidate = this.getCurrentDocumentCandidate();

                if (editedItemIsValidToday && !candidate) {
                    return 'Załącznik pozostanie obowiązującym dokumentem';
                } else {
                    if (!candidate) {
                        return 'Wpis przestanie obowiązywać';
                    } else {
                        return `Nowym obowiązującym dokumentem zostanie: ${candidate.name}`;
                    }
                }
            }

            const editedItemIsExpired =
                dateOfIssue.isBefore(now, 'day') && (!dueDate || (dueDate && dueDate.isSameOrBefore(now, 'day')));

            if (status === 'expired' && editedItemIsExpired) {
                return 'Ten załącznik pozostanie wygasłym dokumentem';
            }

            return this.bannerText;
        }

        get attachmentsIssueDates() {
            return this.parsedAttachments.map((el) => el.date_of_issue);
        }

        get formTitle(): string {
            return this.edit ? 'Edytuj załącznik' : 'Załącz dokument';
        }

        get currentAttachment() {
            return this.attachments.find((el) => el.status === 'current');
        }

        public created() {
            this.$set(this.itemData, 'rent_document_id', this.rentDocumentId);
            this.$set(this.itemData, 'rent_id', this.rentId);

            if (this.edit) {
                if (this.itemData.media_file) {
                    this.mediaFile = this.itemData.media_file;
                }

                if (this.itemData.date_of_issue) {
                    this.initialDateOfissue = this.itemData.date_of_issue;
                }

                if (this.itemData.due_date) {
                    this.initialDueDate = this.itemData.due_date;
                }
            }

            /**/
        }

        public getCurrentDocumentCandidate() {
            const candidate =
                this.attachments.find((el) => {
                    const dueDate = el.due_date ? moment(el.due_date) : null;
                    return el.status !== 'current' && (!dueDate || dueDate.isSameOrBefore(moment.now(), 'days'));
                }) || null;

            if (!candidate) {
                return candidate;
            }
            {
                const dueDate = candidate.due_date ? moment(candidate.due_date) : null;
                const dateOfIssue = moment(candidate.date_of_issue);
                const current = this.itemData.status === 'current' ? this.itemData : this.currentAttachment;

                const currentDateOfIssue = current ? moment(current.date_of_issue) : null;

                const candidateNotExpired = !dueDate || dueDate.isBefore(moment.now(), 'days');
                const candidateAfterCurrent = !current || dateOfIssue.isAfter(currentDateOfIssue, 'days');
                const currentIsAfterNow = currentDateOfIssue ? currentDateOfIssue.isAfter(moment.now()) : false;

                if (candidateNotExpired && (currentIsAfterNow || candidateAfterCurrent)) {
                    return candidate;
                }

                return null;
            }
        }

        public onDatePicked(propertyToSet: string, value: string | null) {
            this.$set(this.itemData, propertyToSet, value);
        }

        public initForm() {
            this.form = this.$refs.createRentDocumentAttachmentAttachmentForm;
        }

        @Watch('mediaFile')
        public updateMediaFile(val: MediaFile) {
            this.$set(this.itemData, 'media_file_id', val.id);
        }
    }
