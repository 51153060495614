
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import { rentRoomTypesModule } from '@/modules/rents/shared/state/state';

// dataRequest
import { RentRoomDataRequest } from '@/modules/rents/shared/requests/rent-room-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: { FormActions },
})
export default class ObjectsTypesSettingsForm extends FormComponent<RentRoomDataRequest> {
    public actionsTypes = rentRoomTypesModule.actionsTypes;
    public store = this.$store.state.rentsState.roomTypesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentRoomDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj typ pomieszczenia' : 'Dodaj typ pomieszczenia';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }
}
