
import { Component } from 'vue-property-decorator';
import { mdiEmail, mdiPhone } from '@mdi/js';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TitleBar from '@/shared/components/layout/details/title-bar.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { RentDocumentNoteDataRequest } from '@/modules/rents/shared/requests/rent-document-note-data-request';
import { rentDocumentNotesModule } from '@/modules/rents/shared/state/state';

@Component({
        props: {
            id: Number,
        },
        components: {
            TitleBar,
        },
    })
    export default class NoteDetails extends DetailsComponent<RentDocumentNoteDataRequest> {
        public icons: object = {
            mdiPhone,
            mdiEmail,
        };
        public props: ModuleProps = rentDocumentNotesModule.moduleProps;
        public mutationTypes = rentDocumentNotesModule.mutationsTypes;
        public actionsTypes = rentDocumentNotesModule.actionsTypes;
        public showAction = this.actionsTypes.SHOW_ITEM;
        public store = this.$store.state.rentsState.rentDocumentNotesState;

        public created() {
            if (Number(this.id)) {
                this.fetchDetails();
            }
        }
    }
