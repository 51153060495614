export class RentPlanDataRequest {
    public id: number = 0;
    public name?: string = '';
    public code?: string = '';
    // tslint:disable-next-line:variable-name
    public change_catalog_id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_type_id: number = 0;
    // tslint:disable-next-line:variable-name
    public public_status: string = '';
    // tslint:disable-next-line:variable-name
    public private_status: string = '';
    // tslint:disable-next-line:variable-name
    public show_price_per_meter: boolean = false;
    // tslint:disable-next-line:variable-name
    public show_price: boolean = false;
    // tslint:disable-next-line:variable-name
    public price: number = 0;
    // tslint:disable-next-line:variable-name
    public description: string = '';

    public storey: number = 0;
    // tslint:disable-next-line:variable-name
    public media_file_id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_id: number = 0;
}
