
    // @ts-ignore
    import Component from 'vue-class-component';
    import pdf from 'vue-pdf-app';
    import 'vue-pdf-app/dist/icons/main.css';
    import Vue from 'vue';
    import { Prop } from 'vue-property-decorator';

    @Component({
        components: {
            pdf,
        },
    })
    export default class PdfPreview extends Vue {
        @Prop(String) public url!: string;
        public pdfLoaded = false;

        public pdfViewerConfig = {
            toolbar: false,
        };

        get isDarkTheme() {
            return this.$vuetify.theme.dark;
        }

        get pdfComponentStyling() {
            return `
            background: none;
            height: calc(100vh - 208px);
            transition: all 200ms ease
        `;
        }

        public mounted() {
            this.pdfLoaded = false;
        }

        public onPDFCreated(PDFViewerApp: any) {
            this.pdfLoaded = true;
            if (PDFViewerApp.pdfSidebar.isOpen) {
                PDFViewerApp.pdfSidebar.toggle();
            }
            PDFViewerApp.pdfViewer.currentScaleValue = 'page-fit';
        }
    }
