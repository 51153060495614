
import { Component } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import IconChooser from '@/shared/components/layout/form/icon-chooser.vue';
import { rentsModule, rentTypesModule } from '@/modules/rents/shared/state/state';
import { RentDataRequest } from '@/modules/rents/shared/requests/rent-data-request';
import { ModuleState } from '@/shared/state/template/module-state';
import { Rent } from '@/modules/rents/shared/models/rent';
import RentTypesForm from '../settings/rent-types/form.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import Vue from 'vue/types/umd';
import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';

import DatePicker from '@/shared/components/elements/date-picker.vue';
import { callSuper } from '@/shared/helpers';

import { actionsTypes as adminActionsTypes } from '@/modules/admin/shared/state/types';
import { rentsMediaAvailable } from '@/shared/config/rents';

@Component({
    components: {
        Wysiwyg,
        ActionConfirmDialog,
        FormActions,
        IconChooser,
        AutoCompleteWithAddNew,
        DatePicker,
        RentTypesForm,
    },
})
export default class RentForm extends FormComponent<RentDataRequest> {
    public store: ModuleState<Rent, RentDataRequest> =
        this.$store.state.rentsState;
    public rentTypesModule = rentTypesModule;
    public actionsTypes = rentsModule.actionsTypes;
    public typesActions = rentTypesModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public mediaAvailable = rentsMediaAvailable;

    public codeInput: any = null;
    public nameInput: any = null;
    public preventDialog: boolean = false;
    public requestData = new RentDataRequest();
    public searchTypesTimer: any = null;
    public rentTypesState = this.$store.state.rentsState.rentTypesState;
    public rentTypesFetchAction = rentTypesModule.actionsTypes.FETCH_DATA;

    public RentTypesForm = RentTypesForm;

    public searchLand = '';
    public currentTab: number = 0;

    public users: any = [];
    public assignedUserLoading: boolean = false;

    public cancelForm() {
        this.preventDialog = false;
        callSuper(this, 'cancelForm', );
    }

    get rentTypes() {
        return this.rentTypesState.data;
    }

    public putInCode(text: string) {
        if (!this.itemData.code) {
            return text;
        }
        return (
            this.itemData.code.slice(0, this.codeInput.selectionStart) +
            text +
            this.itemData.code.slice(
                this.codeInput.selectionStart === this.codeInput.selectionEnd
                    ? this.codeInput.selectionStart
                    : this.codeInput.selectionEnd,
            )
        );
    }

    public putInName(text: string) {
        if (!this.itemData.name) {
            return text;
        }
        return (
            this.itemData.name.slice(0, this.nameInput.selectionStart) +
            text +
            this.itemData.name.slice(
                this.nameInput.selectionStart === this.nameInput.selectionEnd
                    ? this.nameInput.selectionStart
                    : this.nameInput.selectionEnd,
            )
        );
    }

    get rentTypesLoading() {
        return this.rentTypesState.loading;
    }

    get formTitle() {
        return this.edit ? 'Edytuj przedmiot inwestycji' : 'Dodaj przedmiot inwestycji';
    }

    get currentUser() {
        return this.$store.state.authState.user;
    }

    public pullToArraysHeadCurrentUser(arrayUsers: [any]) {
        const currentArray = [...arrayUsers];
        const { id, name } = this.currentUser;
        const currentUser = {
            ...this.currentUser,
            name: `(Ja) ${name}`,
        };
        const arrayWithoutCurrentUser = this._.filter(currentArray, this._.negate(this._.matches({ id })));
        return [currentUser, ...arrayWithoutCurrentUser];
    }

    public created() {
        this.assignedUserLoading = true;
        this.$store.dispatch(this.typesActions.FETCH_DATA, {
            simple: false,
        });

        this.$store
            .dispatch(adminActionsTypes.FETCH_USER_DATA)
            .then((res) => {
                this.users = this.pullToArraysHeadCurrentUser(res.data);
            })
            .finally(() => {
                this.assignedUserLoading = false;
            });
        if (this.itemData.renters && this.itemData.renters.length > 0) {
            this.itemData.renters = this.itemData.renters.map((renter: any) => {
                return renter.id;
            });
        }
    }

    public initForm() {
        this.initFocus();
        this.form = this.$refs.createRentForm;
        setTimeout(() => {
            this.nameInput = this.$refs.nameInput as Vue;
            this.nameInput = this.nameInput.$el.querySelector('input');
            this.codeInput = this.$refs.codeInput as Vue;
            this.codeInput = this.codeInput.$el.querySelector('input');
        }, 0);
    }

    public checkName(name: any) {
        if (name !== null) {
            this.changeName(name);
        } else {
            name = '';
            this.changeName(name);
        }
    }

    public changeName(value: any) {
        if (this.itemData && !this.itemData.name) {
            this.itemData.name = value;
        }
    }
}
