
import ListComponent from '@/shared/components/layout/list/list-component';
import { Rent } from '@/modules/rents/shared/models/rent';
import { RentDataRequest } from '../shared/requests/rent-data-request';
import { Component } from 'vue-property-decorator';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import {
    rentsListHeaders,
} from '@/modules/rents/shared/config';
import {
    rentTypesModule,
    rentsModule,
} from '@/modules/rents/shared/state/state';
import RentForm from '@/modules/rents/components/details/form.vue';
import { FooterProps } from '@/shared/components/layout/list/models/footer-props';
import { logger } from '@/shared/services';
import { callSuper, sortItemsTree } from '@/shared/helpers';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentState } from '@/modules/rents/shared/state/state/state';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        NameReduce,
        FormActions,
        ItemsTable,
        RentForm,
        TooltipBtn,
    },
})
export default class RentList extends ListComponent<
    Rent,
    RentDataRequest
> {
    public headers: object[] = rentsListHeaders;
    public actionsTypes = rentsModule.actionsTypes;
    public rentTypesActions = rentTypesModule.actionsTypes;
    public mutationTypes = rentsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: RentState = this.$store.state.rentsState;
    public itemsCount = [10, 25, 50, 100, 500, 1000, 5000];
    public footer = new FooterProps(this.itemsCount);
    public props: ModuleProps = rentsModule.moduleProps;
    public load: boolean = false;
    public copyRentsDialog: boolean = false;
    public removeRentsDialog: boolean = false;

    public editRentRequest() {
        this.editedItem = new RentDataRequest();
        this.$router.push({ name: 'edit-rents' });
    }

    get rentTypes() {
        return this.$store.state.rentsState.rentTypesState.dataList;
    }

    get rents() {
        return this.$store.state.rentsState.dataList;
    }

    get rentsLoading() {
        return this.$store.state.rentsState.loadingList;
    }

    get rentTypesLoading() {
        return this.$store.state.rentsState.rentTypesState.loadingList;
    }

    public created() {
        this.fetchData();
    }

}
