
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';
import TextContrast from '@/shared/components/elements/text-contrast.vue';

import { RentDocumentCategory } from '@/modules/rents/shared/models/documents/rent-document-category';
import { rentDocumentCategoryListHeaders } from '@/modules/rents/shared/config';
import { RentDocumentCategoryDataRequest } from '@/modules/rents/shared/requests/rent-document-category-data-request';
import { rentDocumentCategoriesModule } from '@/modules/rents/shared/state/state';
import RentDocumentCategoriesForm from './form.vue';

@Component({
    components: {
        ItemsTable,
        RentDocumentCategoriesForm,
        TextContrast,
        IconContrast,
    },
})
export default class CategoriesList extends ListComponent<RentDocumentCategory, RentDocumentCategoryDataRequest> {
    public headers: object[] = rentDocumentCategoryListHeaders;
    public actionsTypes = rentDocumentCategoriesModule.actionsTypes;
    public mutationTypes = rentDocumentCategoriesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.rentsState.rentDocumentCategoriesState;
    public props: ModuleProps = rentDocumentCategoriesModule.moduleProps;

    public mounted() {
        this.fetchData();
    }

}
