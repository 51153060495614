
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';

// state module
import { rentTypesModule } from '../../../shared/state/state';
import { RentTypeDataRequest } from '../../../shared/requests/rent-type-data-request';
import { rentTypes, rentPurposes } from '@/shared/config/rents';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class RentTypesForm extends FormComponent<RentTypeDataRequest> {
    public actionsTypes = rentTypesModule.actionsTypes;
    public store = this.$store.state.rentsState
        .rentTypesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentTypeDataRequest();
    public rentTypes = rentTypes;
    public rentPurposes = rentPurposes;

    public created() {
        //
    }

    public estateTypeChanged() {
        this.clearError('estate_type');
        this.clearError('rent_purpose');
        this.$delete(this.itemData, 'rent_purpose');
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj typ przedmiotu najmu' : 'Dodaj typ przedmiotu najmu';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }
}
