
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { rentDocumentLogsModule } from '@/modules/rents/shared/state/state';
import { rentDocumentLogsListHeaders } from '@/modules/rents/shared/config';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleState } from '@/shared/state/template/module-state';
import ItemsTable from '@/shared/components/layout/list.vue';
import { RentDocumentLog } from '@/modules/rents/shared/models/documents/rent-document-log';
import { rentDocumentLogNames } from '@/shared/config/rent-documents';
import DocumentChangeDetails from './document-details.vue';
import AttachmentChangeDetails from './attachment-details.vue';
import NoteChangeDetails from './note-details.vue';

@Component({
    components: {
        ItemsTable,
        DocumentChangeDetails,
        AttachmentChangeDetails,
        NoteChangeDetails,
    },
})
export default class RentDocumentLogsList extends ListComponent<RentDocumentLog, {}> {
    @Prop(Number) public documentId!: number;
    public props: ModuleProps = rentDocumentLogsModule.moduleProps;
    public headers = rentDocumentLogsListHeaders;
    public actionsTypes = rentDocumentLogsModule.actionsTypes;
    public mutationTypes = rentDocumentLogsModule.mutationsTypes;
    public removeAction = '';
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public store: ModuleState<RentDocumentLog, {}> = this.$store.state.rentsState.rentDocumentLogsState;

    get detailsComponent() {
        if (!this.store.current || !this.store.current.title) { return null; }

        const logElementName = this.store.current.title.split('-')[1];

        switch (logElementName) {
            case 'document':
                return 'DocumentChangeDetails';
            case 'attachment':
                return 'AttachmentChangeDetails';
            case 'note':
                return 'NoteChangeDetails';
        }
    }

    public created() {
        this.id = this.documentId;
    }

    public logName(logName: string) {
        return rentDocumentLogNames.find((obj) => obj.id === logName)?.name;
    }
}
