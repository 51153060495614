

import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleState} from '@/shared/state/template/module-state';
import {
    rentPlansModule,
    rentRoomsModule
} from '@/modules/rents/shared/state/state';
import ItemsTable from '@/shared/components/layout/list.vue';
import RentRoomDetails from '../details/rooms/details.vue';
import PlanForm from '@/modules/rents/components/plans/form.vue';
import { RentPlanDataRequest } from '../../shared/requests/rent-plan-data-request';
import { RentPlan } from '../../shared/models/rent-plan';
import StoreyPlan from '@/modules/rents/components/plans/plan-select.vue';
import { RentRoomsState } from '../../shared/state/state/rooms';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { SaveResponse } from '@/shared/types';
import { rentRoomsListHeaders } from '../../shared/config';

@Component({
    components: {
        ItemsTable,
        RentRoomDetails,
        PlanForm,
        ActionConfirmDialog,
        StoreyPlan,
    },
    props: {
        rentId: Number,
    },
})
export default class RentPlanList extends ListComponent<RentPlan,
    RentPlanDataRequest> {
    public actionsTypes = rentPlansModule.actionsTypes;
    public mutationTypes = rentPlansModule.mutationsTypes;
    public roomsMutations = rentRoomsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers = rentRoomsListHeaders;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public props = rentPlansModule.moduleProps;
    public rentId!: number;
    public roomsState: RentRoomsState = this.$store.state.rentsState.rentRoomsState;
    public store: ModuleState<RentPlan,
        RentPlanDataRequest> = this.$store.state.rentsState.rentPlansState;
    public showMenu: boolean = false;
    public x: number = 0;
    public y: number = 0;
    public requestData = new RentPlanDataRequest();

    public created() {
        this.id = this.rentId;
        this.fetchData();
    }

    get editedRoom() {
        return this.$store.state.rentsState.rentRoomsState.editedItem;
    }

    set editedRoom(val) {
        this.$store.commit(this.roomsMutations.UPDATE_EDITED, val);
    }

    public itemData() {
        return {
            rent_id: this.rentId,
        };
    }

    public planSaved(e: SaveResponse<RentPlan>) {
        this.$emit('saved');
        if (this.roomsState.formDialog) {
            this.showDialog = true;
            this.current = Object.assign({}, e.data);
            this.roomsState.formDialog = false;
            this.pointSelecting = true;
        }
        this.itemSaved();
    }

    get pointSelecting() {
        return this.roomsState.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.roomsMutations.UPDATE_POINT_SELECTING, value);
    }

    get columns() {
        switch (this.$vuetify.breakpoint.name) {
            case 'lg':
                return 2;
            case 'xl':
                return 3;
            default:
                return 3;
        }
    }

    public openMenu(e: MouseEvent, item: RentPlan) {
        e.preventDefault();
        this.current = item;
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
            this.showMenu = true;
        });
    }
}

