import {RentRoom} from '@/modules/rents/shared/models/rent-room';

export const getNextCode = (rooms: RentRoom[]) => {
    if (rooms.length < 1) {
        return 1;
    } else {
        const codes = rooms.map(({code}: RentRoom) => code);
        const lastCode = Math.max(...codes);
        return ((lastCode || 0) + 1);
    }
};
