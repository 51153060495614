
import { Component, Prop } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentRoomDataRequest } from '@/modules/rents/shared/requests/rent-room-data-request';
import {
    rentRoomsModule,
    rentRoomTypesModule,
} from '@/modules/rents/shared/state/state';
import RoomsForm from '@/modules/rents/components/settings/rooms/form.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { RentRoomType } from '@/modules/rents/shared/models/rooms/rent-room-type';
import StoreyPlan from '@/modules/rents/components/plans/plan-select.vue';
import {rentPlansModule} from '@/modules/rents/shared/state/state';
import { RentPlan } from '@/modules/rents/shared/models/rent-plan';
import { RentRoomsState } from '@/modules/rents/shared/state/state/rooms';
import { RentPlanDataRequest } from '@/modules/rents/shared/requests/rent-plan-data-request';
import {ModuleState} from '@/shared/state/template/module-state';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { RentRoom } from '@/modules/rents/shared/models/rent-room';

@Component({
    components: {
        ActionConfirmDialog,
        FormActions,
        AutoCompleteWithAddNew,
        StoreyPlan,
        RoomsForm,
    },
    props: {
        itemData: Object,
        edit: {
            type: Boolean,
            default: false,
        },
        show: Boolean,
        next: {
            type: Boolean,
            default: true,
        },
    },
})
export default class RentRoomForm extends FormComponent<RentRoomDataRequest> {
    public store: RentRoomsState = this.$store.state.rentsState.rentRoomsState;
    public planState: ModuleState<RentPlan,
        RentPlanDataRequest> = this.$store.state.rentsState.rentPlansState;
    public mutationsTypes = rentRoomsModule.mutationsTypes;
    public actionsTypes = rentRoomsModule.actionsTypes;
    public typesActions = rentRoomTypesModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentRoomDataRequest();
    public roomTypesState = this.$store.state.rentsState
        .roomTypesState;
    public rentRoomTypesModule = rentRoomTypesModule;
    public planMutationTypes = rentPlansModule.mutationsTypes;
    public RoomsForm = RoomsForm;
    public noPlanDialog: boolean = false;

    get formTitle() {
        return this.edit ? 'Edytuj pomieszczenie przedmiotu' : 'Dodaj pomieszczenie przedmiotu';
    }

    get roomTypes() {
        return this.$store.state.rentsState.roomTypesState.data;
    }

    get typesLoading() {
        return this.$store.state.rentsState.roomTypesState.loading;
    }

    public roomCodeExists(code: number) {
        const rooms = this.store.dataList as RentRoom[];
        return !rooms.find((room) => room.code === Number(code) && this.itemData.id !== room.id) || 'Istnieje pomieszczenie z takim numerem';
    }

    public initForm() {
        this.form = this.$refs.createRentRoomForm;
         }

    public changeName(type: RentRoomType) {
        if (this.store.editedItem && !this.store.editedItem.name) {
            this.store.editedItem.name = type ? type.name : '';
        }
    }

    public closeNoPlanDialog() {
        this.noPlanDialog = false;
        this.planState.formDialog = true;
    }

    public openSelectPoint() {
        const storeyPlan = this.plans.find(
            ({storey}: RentPlan) => storey === Number(this.itemData.storey)
        );
        if (storeyPlan) {
            this.currentPlan = storeyPlan;
            if (!this.itemData.x) {
                this.$set(this.itemData, 'x', 0);
            }
            if (!this.itemData.y) {
                this.$set(this.itemData, 'y', 0);
            }
            this.$emit('select-point');
            this.pointSelecting = true;
            this.planState.showDialog = true;
        } else {
            this.planState.editedItem = Object.assign({}, {
                storey: this.itemData.storey,
                rent_id: this.itemData.rent_id,
            }) as RentPlanDataRequest;
            this.noPlanDialog = true;
        }
    }

    get pointSelecting() {
        return this.store.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.mutationsTypes.UPDATE_POINT_SELECTING, value);
    }

    get showPlanDialog() {
        return this.planState.showDialog;
    }

    set showPlanDialog(value) {
        this.$store.commit(this.planMutationTypes.UPDATE_SHOW_DIALOG, value);
    }

    get currentPlan() {
        return this.planState && this.planState.current ?
            this.planState.current as RentPlan :
            {} as RentPlan;
    }

    set currentPlan(value) {
        this.$store.commit(this.planMutationTypes.UPDATE_CURRENT, value);
    }

    get plans() {
        return this.planState.data;
    }

    public initFocus() {
        if (!this.show) {
            this.form.reset();
        }
    }

    public created() {
        //
    }
}
