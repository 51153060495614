
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentDocumentDataRequest } from '@/modules/rents/shared/requests/rent-document-data-request';
import { rentDocumentCategoriesModule, rentDocumentsModule } from '@/modules/rents/shared/state/state';
import { documentStatuses } from '@/shared/config/rent-documents';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import RentDocumentCategoriesForm from './categories/form.vue';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { MediaFile } from '@/modules/media/shared/models/media-file';
import moment from 'moment';
import { RentDocumentAttachmentDataRequest } from '../../shared/requests/rent-document-attachment-data-request';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import ChipColor from '@/shared/components/elements/chip-color.vue';
import { callSuper } from '@/shared/helpers';
import { RentDocumentCategory } from '../../shared/models/documents/rent-document-category';

@Component({
    components: {
        FormActions,
        DatePicker,
        AutoCompleteWithAddNew,
        MediaFileInput,
        ChipColor,
    },
})
export default class RentDocumentForm extends FormComponent<RentDocumentDataRequest> {
    public store = this.$store.state.rentsState.rentDocumentsState;
    public actionsTypes = rentDocumentsModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentDocumentDataRequest();
    public rentDocumentsStatuses: any = documentStatuses;

    public categoriesForm = RentDocumentCategoriesForm;
    public categoriesModule = rentDocumentCategoriesModule;
    public categoriesState = this.$store.state.rentsState.rentDocumentCategoriesState;

    public attachDocument = false;

    public mediaFile: MediaFile | null = null;
    public category: RentDocumentCategory | null = null;
    public dueDateText = '';
    public dateOfIssueText = '';

    get plannedRevisionDisabled() {
        if (!this.category) {
            return false;
        }
        return !this.category.has_revisions;
    }

    get dueDateErrorMessages() {
        if (!this.itemData.attachment || !this.itemData.attachment.due_date) {
            return [];
        }
        if (
            this.itemData.attachment.date_of_issue &&
            moment(this.itemData.attachment.due_date).isSameOrBefore(
                moment(this.itemData.attachment.date_of_issue),
                'day',
            )
        ) {
            return ['Niepoprawna wartość'];
        }
        return [];
    }

    get formTitle() {
        return this.edit ? 'Edytuj wpis' : 'Dodaj wpis';
    }

    public created() {
        if (this.edit) {
            this.category = {id: this.itemData.category_id} as RentDocumentCategory;
        }
        this.itemData.attachment = new RentDocumentAttachmentDataRequest();
    }

    public async submitForm(e: any, next?: boolean) {
        if (this.itemData.attachment && !this.itemData.attachment.media_file_id) {
            this.$set(this.itemData, 'attachment', null);
        }
        callSuper(this, 'submitForm', e, next);
    }

    public onDatePicked(propertyToSet: string, value: string | null) {
        if (!this.itemData.attachment) {
            this.itemData.attachment = {} as RentDocumentAttachmentDataRequest;
        }
        this.$set(this.itemData.attachment!, propertyToSet, value);

        if (propertyToSet === 'date_of_issue') {
            this.setDateOfIssueText(value);
        }
    }

    public setDueDateText(dueDate: string | null) {
        this.dueDateText = '';

        if (!dueDate) {
            return;
        }

        const diff = moment(dueDate).diff(moment.now(), 'day');

        if (diff > 0) {
            this.dueDateText = `Pozostało ${moment(dueDate).diff(moment.now(), 'day') + 1} dni`;
        } else if (diff === 0) {
            this.dueDateText = `Pozostał ${moment(dueDate).diff(moment.now(), 'day') + 1} dzień`;
        } else {
            this.dueDateText = `Mineło ${Math.abs(moment(dueDate).diff(moment.now(), 'day'))} dni temu`;
        }
    }

    public setDateOfIssueText(dateOfIssue: string | null) {
        this.dateOfIssueText = '';

        if (!dateOfIssue) {
            return;
        }

        const diff = moment(dateOfIssue).diff(moment.now(), 'day');

        if (diff > 0) {
            this.dateOfIssueText = `Aktywacja za ${moment(dateOfIssue).diff(moment.now(), 'day') + 1} dni`;
        } else if (moment(dateOfIssue).isSame(moment.now(), 'day')) {
            this.dateOfIssueText = `Aktywacja dzisiaj`;
        } else if (diff === 0) {
            this.dateOfIssueText = `Aktywacja za ${moment(dateOfIssue).diff(moment.now(), 'day') + 1} dzień`;
        }
    }

    public initForm() {
        this.form = this.$refs.createDocumentForm;
    }

    @Watch('mediaFile')
    public onMediaFileChange(val: any) {
        if (!this.itemData.attachment) {
            this.itemData.attachment = {} as RentDocumentAttachmentDataRequest;
        }
        this.$set(this.itemData.attachment!, 'media_file_id', val ? val.id : null);
    }

    @Watch('category', {immediate: true})
    public onCategoryChange(val: any) {
        if (!val) {
            return;
        }
        this.$set(this.itemData, 'category_id', val.id);
    }
}
