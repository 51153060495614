
    import { RentDocumentAttachment } from '@/modules/rents/shared/models/documents/rent-document-attachment';
    import DatePicker from '@/shared/components/elements/date-picker.vue';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';
    import { httpClient } from '@/shared/services';
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';

    @Component({
        components: {
            DatePicker,
            FormActions,
        },
    })
    export default class ScheduleRevison extends Vue {
        @Prop(Object) public attachment!: RentDocumentAttachment;
        public plannedRevision!: string | null;
        public valid = true;

        public schedule(val: string | null) {
            this.plannedRevision = val;
        }

        public submit() {
            httpClient
                .put(`/api/v1/rents/documents/${this.attachment.rent_document_id}/attachments/${this.attachment.id}`, {
                    planned_revision: this.plannedRevision,
                })
                .then(({ data }) => {
                    this.$store.commit('SHOW_SNACKBAR', {
                        type: 'success',
                        text: `Pomyślnie zapisano datę przeglądu`,
                    });
                })
                .catch((err) => {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                })
                .finally(() => {
                    this.$emit('saved');
                });
        }
    }
