
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
// state module
import { rentPropertyTypesModule } from '../../../shared/state/state';
// dataRequest
import { RentPropertyTypeDataRequest } from '@/modules/rents/shared/requests/rent-property-type-data-request';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions},
})
export default class RentPropertyTypesForm extends FormComponent<RentPropertyTypeDataRequest> {
    public actionsTypes = rentPropertyTypesModule.actionsTypes;
    public store = this.$store.state.rentsState
        .propertyTypesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentPropertyTypeDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj typ właściwości przedmiotu najmu' : 'Dodaj typ właściwości przedmiotu';
    }

    public initForm() {
        this.form = this.$refs.createDepartmentForm;
    }
}
