
    import ItemsTable from '@/shared/components/layout/list.vue';
    import { Component } from 'vue-property-decorator';
    import ListComponent from '@/shared/components/layout/list/list-component';
    import { ModuleProps } from '@/shared/state/template/module-props';
    import { rentRoomTypesModule } from '@/modules/rents/shared/state/state';
    import { RentRoom } from '@/modules/rents/shared/models/rent-room';
    import { RentRoomDataRequest } from '@/modules/rents/shared/requests/rent-room-data-request';
    import { roomTypesListHeaders } from '@/modules/rents/shared/config';
    import RentRoomTypeForm from './form.vue';

    @Component({
        components: {
            ItemsTable,
            RentRoomTypeForm,
        },
    })
    export default class RentRoomTypesList extends ListComponent<
        RentRoom, RentRoomDataRequest
    > {
        public headers = roomTypesListHeaders;
        public actionsTypes = rentRoomTypesModule.actionsTypes;
        public mutationTypes = rentRoomTypesModule.mutationsTypes;
        public fetchAction: string = this.actionsTypes.FETCH_DATA;
        public removeAction: string = this.actionsTypes.REMOVE_ITEM;
        public store: any = this.$store.state.rentsState.roomTypesState;
        public props: ModuleProps = rentRoomTypesModule.moduleProps;
    }
