
import ChipColor from '@/shared/components/elements/chip-color.vue';
import { documentAttachmentStatuses, rentDocumentLogNames } from '@/shared/config/rent-documents';
import { parseDate } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    components: {
        ChipColor,
    },
})
export default class AttachmentChangeDetails extends Vue {
    public store = this.$store.state.rentsState.rentDocumentLogsState;
    public parseDate = parseDate;

    get item() {
        return this.store.current;
    }

    get name() {
        return rentDocumentLogNames.find((el) => el.id === this.item.title)!.name;
    }

    get changes() {
        return this.item.data;
    }

    get attachmentStatus() {
        if (!this.changes || !this.changes.status) { return null; }
        return documentAttachmentStatuses.find((status) => status.id === this.changes.status) || null;
    }
}
