

import {Component} from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { rentRoomsModule } from '@/modules/rents/shared/state/state';
import {ModuleProps} from '@/shared/state/template/module-props';
import { RentRoom } from '@/modules/rents/shared/models/rent-room';

@Component
export default class RentRoomDetails extends DetailsComponent<RentRoom> {
        public props: ModuleProps = rentRoomsModule.moduleProps;
        public actionsTypes = rentRoomsModule.actionsTypes;
        public showAction = this.actionsTypes.SHOW_ITEM;
        public store = this.$store.state.rentsState.rentRoomsState;

        public created() {
            // this.fetchDetails();
        }
}

