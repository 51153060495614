
import { Component, Watch, Prop } from 'vue-property-decorator';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { parseDate, parsePrice } from '@/shared/helpers';
import RentMedia from '@/modules/rents/components/details/media/media.vue';
import { mdiHome } from '@mdi/js';
import { Rent } from '@/modules/rents/shared/models/rent';
import {
    rentPropertiesModule,
    rentsModule,
} from '@/modules/rents/shared/state/state';
import RentRoomsList from '@/modules/rents/components/details/rooms/list.vue';
import RentForm from '@/modules/rents/components/details/form.vue';
import RentPropertyForm from '@/modules/rents/components/details/properties/form.vue';
import { RentProperty } from '@/modules/rents/shared/models/rent-property';
import { httpClient, logger } from '@/shared/services';
// @ts-ignore
import { ModuleProps } from '@/shared/state/template/module-props';
import { RentState } from '../shared/state/state/state';
import ItemsTable from '@/shared/components/layout/list.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import RentPlanList from '@/modules/rents/components/plans/list.vue';
// import RentCardDialog from '@/modules/rents/components/details/card-dialog.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';
import LinkedEvents from '@/modules/calendar/components/linked-events.vue';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { statuses } from '@/shared/config/rents';
import { Change } from '@/modules/changes/shared/models/change';
import { HeaderFieldType, SaveResponse } from '@/shared/types';
import RentEquipmentList from './details/equipment/list.vue';
import RentReservationForm from './details/reservation/form.vue';
import RentDocumentsList from './documents/list.vue';

@Component({
    components: {
        // RentCardDialog,
        RentPropertyForm,
        RentForm,
        ActionConfirmDialog,
        RentMedia,
        RentRoomsList,
        RentEquipmentList,
        TopToolbar,
        TooltipBtn,
        RentPlanList,
        ObservableWatch,
        LinkedEvents,
        ItemsTable,
        FormActions,
        RentReservationForm,
        RentDocumentsList,
    },
})
export default class RentDetails extends DetailsComponent<Rent> {
    public store: RentState = this.$store.state.rentsState;
    public loadingClient: boolean = false;
    public loadingSettings: boolean = false;
    public saveDialog: boolean = false;
    public actionsTypes = rentsModule.actionsTypes;
    public mutationTypes = rentsModule.mutationsTypes;
    public propertyMutationTypes = rentPropertiesModule.mutationsTypes;
    public showAction: string = this.actionsTypes.SHOW_ITEM;
    public moduleProps: ModuleProps = rentsModule.moduleProps;
    public parseDate = parseDate;
    public tab: any = 0;
    public icons: object = {
        mdiHome,
    };
    public formKey = new Date().toString() + Math.random();

    public rentPropertiesState = this.$store.state.rentsState.rentPropertiesState;
    public propertiesActions = rentPropertiesModule.actionsTypes;

    public units = [];
    public selected: Change[] = [];
    public reservationFormDialog: boolean = false;
    public oldPrivateStatus: string = '';

    get loadingProperties() {
        return this.rentPropertiesState.loading;
    }

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
    }

    get itemStatus() {
        return statuses.find((status) => status.value === this.item.status);
    }

    get itemStatuses() {
        return statuses;
    }

    get removePropertyDialog() {
        return this.rentPropertiesState.removeDialog;
    }

    set removePropertyDialog(value) {
        this.$store.commit(this.propertyMutationTypes.UPDATE_REMOVE_DIALOG, value);
    }
    get propertyFormDialog() {
        return this.rentPropertiesState.formDialog;
    }

    set propertyFormDialog(value) {
        this.$store.commit(this.propertyMutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get propertyEditedItem() {
        return this.rentPropertiesState.editedItem;
    }
    set propertyEditedItem(value) {
        this.$store.commit(this.propertyMutationTypes.UPDATE_EDITED, value);
    }

    get formDialog() {
        return this.store.formDialog;
    }

    set formDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get rentProperties() {
        return this.rentPropertiesState.data;
    }

    public created() {
        this.fetchDetails();
        this.fetchProperties();
    }

    public cancelReservationForm() {
        this.reservationFormDialog = false;
    }
    public openReservationEditForm() {
        this.reservationFormDialog = true;
    }
    public openPropertyForm() {
        this.propertyFormDialog = true;
        this.propertyEditedItem = {
            rent_id: Number(this.id),
            public: false,
        };
    }
    public isPermitted(action: string) {
        return this.permissionCheck(`rents.${action}`);
    }

    public download() {
        httpClient
            .get(`/rents/${this.item.uuid}/card`, {
                headers: {},
                responseType: 'arraybuffer',
                method: 'GET',
            })
            .then((response) => {
                if (response.data && response.data instanceof ArrayBuffer) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'karta' + '.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
            });
    }

    public editItem() {
        this.editedItem = { ...this.item };
        this.formDialog = true;
    }


    public reservationItemSaved() {
        this.cancelReservationForm();
        this.fetchDetails();
    }

    public editProperty(property: RentProperty) {

        this.propertyFormDialog = true;
        this.propertyEditedItem = Object.assign({}, property);
    }

    public removeProperty(property: RentProperty) {
        this.removePropertyDialog = true;
        this.propertyEditedItem = property;
    }

    public removePropertyRequest() {
        this.$store
            .dispatch(this.propertiesActions.REMOVE_ITEM, this.propertyEditedItem.id)
            .then((response) => {
                if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                    this.fetchProperties();
                    this.removePropertyDialog = false;
                }
            })
            .catch((err) => logger.error(err));
    }

    public propertyItemSaved() {
        this.propertyFormDialog = false;
        this.fetchProperties();
    }

    public fetchProperties() {
        this.$store.dispatch(this.propertiesActions.FETCH_DATA, {
            id: this.id,
        });
    }

    public itemSaved() {
        this.formDialog = false;
        this.fetchDetails();
    }

    public onPlanSelection() {
        this.fetchDetails();
    }

    @Watch('formDialog')
    public onFormDialogChange(val: boolean) {
        if (val) {
            this.formKey =  new Date().toString() + Math.random();

        }
    }

}
