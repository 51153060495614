export const documentStatuses = [
    {id: 'no-attachment', name: 'Dokument do załączenia', color: '#7C7C7C'},
    {id: 'active', name: 'Aktualny', color: '#0D8B0D'},
    {id: 'will-expire-soon', name: 'Kończy się', color: '#E65224'},
    {id: 'expired', name: 'Wygasły', color: '#E21B1B'},
    {id: 'unactive', name: 'Nie aktywny', color: '#7F10F1'},
];

export const documentRevisionStatuses = [
    {id: 'not-scheduled', name: 'Do umówienia', color: '#E65224'},
    {id: 'scheduled', name: 'Umówiony', color: '#0D8B0D'},
    {id: 'expired', name: 'Wygasły', color: '#E21B1B'},
];

export const documentAttachmentStatuses = [
    {id: 'current', name: 'Obowiązujący', color: '#1867c0'},
    {id: 'expired', name: 'Wygasły', color: '#FF552a'},
    {id: 'following', name: 'Przyszły', color: '#7F10F1'},
];

export const rentDocumentLogNames = [
    {id: 'create-document', name: 'Utworzenie wpisu'},
    {id: 'update-document', name: 'Edycja wpisu'},
    {id: 'create-attachment', name: 'Dodanie dokumentu'},
    {id: 'update-attachment', name: 'Edycja dokumentu'},
    {id: 'delete-attachment', name: 'Usunięcie dokumentu'},
    {id: 'create-note', name: 'Dodanie notatki'},
    {id: 'update-note', name: 'Edycja notatki'},
    {id: 'delete-note', name: 'Usunięcie notatki'},
];
