
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import { rentPropertyTypesModule } from '@/modules/rents/shared/state/state';
import { RentPropertyType } from '@/modules/rents/shared/models/rent-property-types';
import { RentPropertyTypeDataRequest } from '@/modules/rents/shared/requests/rent-property-type-data-request';
import RentPropertyTypesForm from './rent-property-types-form.vue';
import { rentPropertyTypesListHeaders } from '@/modules/rents/shared/config';

@Component({
    components: {
        ItemsTable,
        RentPropertyTypesForm,
    },
})
export default class RentPropertiesTypesList extends ListComponent<RentPropertyType,
    RentPropertyTypeDataRequest> {
    public headers: object[] = rentPropertyTypesListHeaders;
    public actionsTypes =
        rentPropertyTypesModule.actionsTypes;
    public mutationTypes =
        rentPropertyTypesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.rentsState
        .propertyTypesState;
    public props: ModuleProps =
        rentPropertyTypesModule.moduleProps;
}
