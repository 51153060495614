
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';
import RentTypesForm from './form.vue';
import { rentTypesModule } from '@/modules/rents/shared/state/state';
import { RentType } from '@/modules/rents/shared/models/rent-type';
import { RentTypeDataRequest } from '@/modules/rents/shared/requests/rent-type-data-request';
import { rentsTypeListHeaders } from '@/modules/rents/shared/config';

@Component({
    components: {
        ItemsTable,
        RentTypesForm,
    },
})
export default class RentTypesList extends ListComponent<RentType,
    RentTypeDataRequest> {
    public headers: object[] = rentsTypeListHeaders;
    public actionsTypes = rentTypesModule.actionsTypes;
    public mutationTypes = rentTypesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.rentsState.rentTypesState;
    public props: ModuleProps = rentTypesModule.moduleProps;
}
