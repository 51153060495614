
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentDocumentCategoryDataRequest } from '@/modules/rents/shared/requests/rent-document-category-data-request';
import { rentDocumentCategoriesModule } from '@/modules/rents/shared/state/state';



@Component({
    components: {
        FormActions,
    },
})
export default class RentDocumentCategoriesForm extends FormComponent<RentDocumentCategoryDataRequest> {
    public actionsTypes = rentDocumentCategoriesModule.actionsTypes;
    public store: any = this.$store.state.rentsState.rentDocumentCategoriesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentDocumentCategoryDataRequest();


    public swatches: Array<[string, string]> = [
        ['#753BBD', '#147BD1'],
        ['#2DC84D', '#FF7F41'],
        ['#F7EA48', '#ea97ad'],
        ['#4A6695', '#E03C31'],
        ['#B7CC33', '#FF00A5'],
    ];

    // for autocomplete with add new

    public created() {
        if (this.itemData.has_revisions === undefined) {
            this.$set(this.itemData, 'has_revisions', false);
        }
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj kategorię' : 'Dodaj kategorię';
    }

    public initForm() {
        this.form = this.$refs.createRentDocumentCategoryCategoryForm;
         }

    // HELPERS

    public isString(value: any) {
        return typeof value === 'string';
    }

    @Watch('itemData.color')
    public ifColorObject() {
        if (!(typeof this.itemData.color === 'string')) { this.itemData.color = '#147BD1'; }
    }
}
