
    import ChipColor from '@/shared/components/elements/chip-color.vue';
    import { documentRevisionStatuses, documentStatuses, rentDocumentLogNames } from '@/shared/config/rent-documents';
    import { parseDate } from '@/shared/helpers';
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        components: {
            ChipColor,
        },
    })
    export default class DocumentChangeDetails extends Vue {
        public store = this.$store.state.rentsState.rentDocumentLogsState;
        public parseDate = parseDate;

        get item() {
            return this.store.current;
        }

        get name() {
            return rentDocumentLogNames.find((el) => el.id === this.item.title)!.name;
        }

        get changes() {
            return this.item.data;
        }

        get hasRevisions() {
            if (!this.changes || !this.changes.category) {
                return false;
            }
            return this.changes.category.has_revisions;
        }

        get documentStatus() {
            if (!this.changes) {
                return null;
            }
            return documentStatuses.find((status) => status.id === this.changes.status) || null;
        }

        get revisionStatus() {
            if (!this.changes || !this.changes.revision_status) {
                return null;
            }
            return documentRevisionStatuses.find((status) => status.id === this.changes.revision_status) || null;
        }
    }
