
import { Component, Prop } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { RentEquipmentDataRequest } from '@/modules/rents/shared/requests/rent-equipment-data-request';
import { rentEquipmentModule } from '@/modules/rents/shared/state/state';

@Component({
    components: {
        FormActions,
    }
})
export default class RentEquipmentForm extends FormComponent<RentEquipmentDataRequest> {
    public store = this.$store.state.rentsState.rentEquipmentState;
    public actionsTypes = rentEquipmentModule.actionsTypes;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentEquipmentDataRequest();

    get formTitle() {
        return this.edit ? 'Edytuj wyposażenie' : 'Dodaj wyposażenie';
    }

    public initForm() {
        this.form = this.$refs.createEquipmentForm;
    }
}


