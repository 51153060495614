
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import { RentDataRequest } from '@/modules/rents/shared/requests/rent-data-request';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { reservationDurations } from '@/shared/config/investments';
import { httpClient } from '@/shared/services';
import { statuses } from '@/shared/config/rents';
import { rentsModule } from '@/modules/rents/shared/state/state';
@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
        DatePicker,
    },
})
export default class RentReservationForm extends FormComponent<RentDataRequest> {
    public renters: any = [];
    public reservingClientLoading: boolean = false;
    public reservationDurations = reservationDurations;
    public reservationDuration = 0;
    public store = this.$store.state.rentsState;
    public actionsTypes = rentsModule.actionsTypes;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public isLoading = false;

    get formTitle(): string {
        return 'Zmień status przedmiotu';
    }

    get currentStatus() {
        return this.$store.state.rentsState.current.status;
    }

    get itemStatus() {
        return statuses.find((status) => status.value === this.itemData.status);
    }

    get itemStatuses() {
        return statuses;
    }

    public created() {
        this.reservingClientLoading = true;
        httpClient.get(`/api/v1/renters`)
            .then((data: any) => {
                this.renters = data.data.data;
                this.renters.map((renter: any) => {
                    if (renter.renting > 0) {
                        renter.disabled = true;
                        if ( this.store.current.renters && this.store.current.renters.length > 0
                                && renter.id === this.store.current.renters[0].id) {
                            renter.disabled = false;
                        }
                    }
                    return renter;
                });
                this.reservingClientLoading = false;
            });
    }
    public onReservationStartDateChange(value: string) {
        if (this.reservationDuration === 0) {
            this.itemData.start_date = this.parseDate(value);
        } else
        if (this.edit) {
            this.itemData.start_date = this.parseDate(value);
            if (this.reservationDuration !== 0) {
            const reservationEndDate = new Date(value);
            reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);
            this.itemData.end_date = this.parseDate(reservationEndDate);
            }
        } else
        if (!this.edit) {
            const reservationEndDate = new Date(value);
            reservationEndDate.setDate(reservationEndDate.getDate() + this.reservationDuration);
            this.itemData.start_date = this.parseDate(value);
            this.itemData.end_date = this.parseDate(reservationEndDate);
        }
    }
    public onReservationEndDateChange(value: string) {
        this.itemData.end_date = this.parseDate(value);
    }
    public onReservationDurationChange() {
        if ( !this.itemData.start_date ) {
            this.itemData.start_date = this.parseDate(new Date());
        }
        const reservationEndDate = new Date(this.store.editedItem.start_date);
        reservationEndDate.setDate( reservationEndDate.getDate() + this.reservationDuration);

        this.$set(this.itemData, 'end_date', this.parseDate(reservationEndDate));
    }
    public updateReservation() {
        this.isLoading = true;
        const data = {
            id: this.$store.state.rentsState.current.id,
            status: this.itemData.status,
            start_date: this.itemData.start_date,
            end_date: this.itemData.end_date,
            renters: [{renter_id: this.itemData.renters}],
        };
        this.$store.dispatch(this.updateAction, data)
        .then(() => {
            this.isLoading = false;
            this.$emit('update');
        })
        .catch(() => {
            this.isLoading = false;
        });
    }
}
