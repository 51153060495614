
import { rentPlansModule } from '../../shared/state/state';
import Component from 'vue-class-component';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import { RentPlanDataRequest } from '../../shared/requests/rent-plan-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import { RentRoomsState } from '../../shared/state/state/rooms';

@Component({
    components: {
        MediaFileInput,
        FormActions,
    },
    props: {
        itemData: Object,
    },
})


export default class PlanForm extends FormComponent<RentPlanDataRequest> {
    public selectedPlan: any = [];
    public planSelectDialog: boolean = false;
    public dialogPlus: boolean = false;
    public btnKon: any = [];
    public requestData = new RentPlanDataRequest();
    public actionsTypes = rentPlansModule.actionsTypes;
    public store: any = this.$store.state.rentsState.rentPlansState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public roomsState: RentRoomsState = this.$store.state.rentsState.rentRoomsState;


    public showMenu: boolean = false;
    public x: number = 0;
    public y: number = 0;

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Dodaj plan dla określonego poziomu' : 'Dodaj plan dla określonego poziomu';
    }

    public showOn(e: any) {
        e.preventDefault();
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
            this.showMenu = true;
        });
    }

    public initForm() {
        this.form = this.$refs.createPlanForm;
         }

    public setBackground(e: MediaFile) {
        if (e) {
            this.clearError('media_file_id');
            this.itemData.media_file_id = e.id;
        }
    }
}
