
import { Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';


import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';

import { RentDocumentNoteDataRequest } from '@/modules/rents/shared/requests/rent-document-note-data-request';
import { rentDocumentNotesModule } from '@/modules/rents/shared/state/state';
import { RentDocumentNote } from '@/modules/rents/shared/models/documents/rent-document-note';
import { ModuleState } from '@/shared/state/template/module-state';

@Component({
    components: { FormActions, AutoCompleteWithAddNew },
})
export default class RentDocumentNotesForm extends FormComponent<RentDocumentNoteDataRequest> {
    public actionsTypes = rentDocumentNotesModule.actionsTypes;
    public mutationTypes = rentDocumentNotesModule.mutationsTypes;
    public store: ModuleState<RentDocumentNote, RentDocumentNoteDataRequest> =
            this.$store.state.rentsState.rentDocumentNotesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new RentDocumentNoteDataRequest();



    get formTitle(): string {
        return this.edit ? 'Edytuj notatkę' : 'Dodaj notatkę';
    }


    public initForm() {
        this.form = this.$refs.createContactForm;
    }

}
