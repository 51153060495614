
import {Component, Prop} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleState} from '@/shared/state/template/module-state';
import { rentEquipmentListHeaders } from '@/modules/rents/shared/config';
import { rentEquipmentModule } from '@/modules/rents/shared/state/state';
import { RentEquipmentDataRequest } from '@/modules/rents/shared/requests/rent-equipment-data-request';
import { RentEquipment } from '@/modules/rents/shared/models/rent-equipment';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import RentEquipmentForm from './form.vue';
@Component({
    components: {
        ItemsTable,
        RentEquipmentForm,
    },
})
export default class RentEquipmentList extends ListComponent<RentEquipment,
    RentEquipmentDataRequest> {
    @Prop(Number) public rentId!: number;
    public props: ModuleProps = rentEquipmentModule.moduleProps;
    public headers = rentEquipmentListHeaders;
    public actionsTypes = rentEquipmentModule.actionsTypes;
    public mutationTypes = rentEquipmentModule.mutationsTypes;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public id = this.rentId;
    public store: ModuleState<RentEquipment,
         RentEquipmentDataRequest> = this.$store.state.rentsState.rentEquipmentState;

    public fetchData() {
        this.$store.dispatch(this.fetchAction, {id: this.rentId});
    }

    public addNew() {
        if (this.store.editedItem) {
            this.store.editedItem.rent_id = this.rentId;
            this.formDialog = true;
        }
    }
}

