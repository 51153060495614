import { MediaFile } from '@/modules/media/shared/models/media-file';

export class RentDocumentAttachmentDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_document_id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_id: number = 0;
    public status!: string;
    // tslint:disable-next-line:variable-name
    public media_file: MediaFile = {} as MediaFile;
    // tslint:disable-next-line:variable-name
    public media_file_id: number = 0;
    // tslint:disable-next-line:variable-name
    public date_of_issue: string = '';
    // tslint:disable-next-line:variable-name
    public due_date: string = '';
    // tslint:disable-next-line:variable-name
    public planned_revision: string = '';
}
