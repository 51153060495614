

import {Component, Prop} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { rentRoomsListHeaders } from '@/modules/rents/shared/config';
import {ModuleState} from '@/shared/state/template/module-state';
import { rentRoomsModule } from '@/modules/rents/shared/state/state';
import ItemsTable from '@/shared/components/layout/list.vue';
import RentRoomDetails from './details.vue';
import RentRoomForm from './form.vue';
import { RentRoomDataRequest } from '@/modules/rents/shared/requests/rent-room-data-request';
import { RentRoom } from '@/modules/rents/shared/models/rent-room';
import { RentRoomsState } from '@/modules/rents/shared/state/state/rooms';
import {SaveResponse} from '@/shared/types';
import { RentPlan } from '@/modules/rents/shared/models/rent-plan';
import { RentPlanDataRequest } from '@/modules/rents/shared/requests/rent-plan-data-request';
import { getNextCode } from '@/modules/rents/shared/helpers/rooms';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ItemsTable,
        RentRoomDetails,
        RentRoomForm,
    },
})
export default class RentRoomsList extends ListComponent<RentRoom,
    RentRoomDataRequest> {
    @Prop(Number) public rentId!: number;
    @Prop(Boolean) public hideDefaultFooter!: boolean;
    @Prop(Boolean) public customCreateItem!: boolean;
    public actionsTypes = rentRoomsModule.actionsTypes;
    public mutationTypes = rentRoomsModule.mutationsTypes;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public headers = rentRoomsListHeaders;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public props = rentRoomsModule.moduleProps;
    public store: RentRoomsState = this.$store.state.rentsState.rentRoomsState;
    public plansState: ModuleState<RentPlan, RentPlanDataRequest> =
        this.$store.state.rentsState.rentPlansState;

    public created() {
        this.id = this.rentId;
    }

    get itemData() {
        const itemData = {
            storey: 0,
        } as RentRoomDataRequest;

        itemData.code = getNextCode(this.store.dataList as RentRoom[]);

        return itemData;
    }

    public closeForm() {
        this.editedItem = {} as RentRoomDataRequest;
        this.pointSelecting = false;
        callSuper(this, 'closeForm');
    }

    public loadAllRooms() {
        this.$store.dispatch(this.fetchAction, {
            id: this.rentId,
            simple: true,
        });
    }

    public roomRemoved() {
        const currentIndex = this.store.dataList.map((e) => e.id).indexOf(this.current.id);
        if (currentIndex !== -1) {
            this.$delete(this.store.dataList, currentIndex);
        }
    }

    public selectPoint() {
        this.formDialog = false;
    }

    get pointSelecting() {
        return this.store.pointSelecting;
    }

    set pointSelecting(value: boolean) {
        this.$store.commit(this.mutationTypes.UPDATE_POINT_SELECTING, value);
    }

    public roomNextSaved() {
        const itemData = {} as RentRoomDataRequest;

        if (this.store.dataList.length > 0) {
            const lastRoom = this.store.dataList[this.store.dataList.length - 1] as RentRoom;
            if (lastRoom) {
                this.$set(itemData, 'storey', lastRoom.storey);
            }
        }

        this.$set(itemData, 'code', getNextCode(this.store.dataList as RentRoom[]));

        this.setDefaultData(itemData);

        this.formDialog = true;
    }

    public roomSaved(response: SaveResponse<RentRoom>) {
        this.$emit('saved');
        if (!response.next) {
            this.pointSelecting = false;
            this.editedItem = {} as RentRoomDataRequest;
        }
        const roomIndex = this.store.dataList.map((e) => e.id).indexOf(response.data.id);
        if (roomIndex !== -1) {
            this.$set(this.store.dataList, roomIndex, response.data);
        } else {
            this.store.dataList.push(response.data);
        }
        this.itemSaved();
    }
}

