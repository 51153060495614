import { RentDocumentAttachmentDataRequest } from './rent-document-attachment-data-request';

export class RentDocumentDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_id: number = 0;
    public name: string = '';
    // tslint:disable-next-line:variable-name
    public category_id: number = 0;
    public attachment: RentDocumentAttachmentDataRequest | null = null;
}
