import { User } from '@/modules/auth/shared/models/user';

export class RentDocumentNoteDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_document_id: number = 0;
    public name: string = '';
    public description: string = '';
    // tslint:disable-next-line:variable-name
    public user_id: number = 0;
    public user!: User;
}
