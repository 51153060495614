
import { Component, Prop, Watch } from 'vue-property-decorator';
import { RentDocumentNote } from '../../../shared/models/documents/rent-document-note';
import { RentDocumentNoteDataRequest } from '../../../shared/requests/rent-document-note-data-request';
import ListComponent from '@/shared/components/layout/list/list-component';
import { rentDocumentNotesModule } from '@/modules/rents/shared/state/state';
import { rentDocumentNotesListHeaders } from '@/modules/rents/shared/config';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleState } from '@/shared/state/template/module-state';
import ItemsTable from '@/shared/components/layout/list.vue';
import NoteDetails from './details.vue';
import RentDocumentNotesForm from './form.vue';
import { SaveResponse } from '@/shared/types';
import { callSuper } from '@/shared/helpers';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ItemsTable,
        NoteDetails,
        RentDocumentNotesForm,
        TooltipBtn,
    },
})
export default class RentDocumentNotesList extends ListComponent<RentDocumentNote, RentDocumentNoteDataRequest> {
    @Prop(Number) public documentId!: number;
    public props: ModuleProps = rentDocumentNotesModule.moduleProps;
    public headers = rentDocumentNotesListHeaders;
    public actionsTypes = rentDocumentNotesModule.actionsTypes;
    public mutationTypes = rentDocumentNotesModule.mutationsTypes;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public store: ModuleState<RentDocumentNote, RentDocumentNoteDataRequest> =
        this.$store.state.rentsState.rentDocumentNotesState;

    public created() {
        this.id = this.documentId;
    }

    public itemSaved(e?: SaveResponse<RentDocumentNoteDataRequest>) {
        this.$emit('itemSaved');
        callSuper(this, 'itemSaved', e);
    }

    @Watch('removeDialog')
    public removeDialogChange(val: boolean) {
        if (!val) { this.$emit('itemDeleted'); }
    }
}
