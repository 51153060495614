
import { RentDocument } from '@/modules/rents/shared/models/documents/rent-document';
import {
    rentDocumentsModule,
    rentDocumentAttachmentsModule,
    rentDocumentLogsModule,
} from '@/modules/rents/shared/state/state';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import PdfPreview from './preview.vue';
import { RentDocumentAttachment } from '@/modules/rents/shared/models/documents/rent-document-attachment';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { httpClient } from '@/shared/services';
import { documentAttachmentStatuses, documentRevisionStatuses, documentStatuses } from '@/shared/config/rent-documents';
import ChipColor from '@/shared/components/elements/chip-color.vue';
import RentDocumentAttachmentsForm from '../attachments/form.vue';
import _ from 'lodash';
import moment from 'moment';
import RentDocumentForm from '../form.vue';
import ScheduleRevison from '../attachments/schedule-revision.vue';
import RentDocumentNotesList from '../notes/list.vue';
import RentDocumentLogsList from '../logs/list.vue';
import ObservableWatch from '@/shared/components/elements/observable-watch.vue';

@Component({
    components: {
        TopToolbar,
        TooltipBtn,
        PdfPreview,
        ChipColor,
        RentDocumentAttachmentsForm,
        RentDocumentForm,
        ScheduleRevison,
        RentDocumentNotesList,
        RentDocumentLogsList,
        ObservableWatch,
    },
})
export default class DocumentDetails extends DetailsComponent<RentDocument> {
    @Prop(String) public rentId!: number;
    @Ref('pdf-preview') public readonly pdfPreview!: Vue;
    public now = moment();
    public currentTab = 0;
    public store = this.$store.state.rentsState.rentDocumentsState;
    public documentsModule = rentDocumentsModule;
    public actionsTypes = this.documentsModule.actionsTypes;
    public mutationTypes = this.documentsModule.mutationsTypes;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public editedItem = {};
    public tab = 0;

    public previewItem: RentDocumentAttachment | null = null;
    public newPreviewItemId = 0;

    public attachmentsStore = this.$store.state.rentsState.rentDocumentAttachmentsState;
    public attachmentsActionTypes = rentDocumentAttachmentsModule.actionsTypes;
    public attachmentsForm = false;
    public editAttachment = false;
    public attachmentItemData = {};
    public loadingAttachments = false;
    public attachmentSelectDialog = false;

    public scheduleRevisonDialog = false;

    get latestAttachment() {
        return this.attachments.filter((el) => el.status !== 'current')[0];
    }

    get formDialog() {
        return this.store.formDialog;
    }

    set formDialog(val: boolean) {
        this.$set(this.store, 'formDialog', val);
    }

    get documentUpdateCount() {
        if (!this.item || !this.item.next_following_attachment) {
            return '';
        }
        return moment(this.item.next_following_attachment.date_of_issue).diff(moment(), 'days') + 1;
    }

    get hasRevisions() {
        if (!this.item || !this.item.category) {
            return false;
        }
        return this.item.category.has_revisions;
    }

    get documentStatus() {
        if (!this.item) {
            return null;
        }
        return documentStatuses.find((status) => status.id === this.item.status) || null;
    }

    get revisionStatus() {
        if (!this.item || !this.item.revision_status) {
            return null;
        }
        return documentRevisionStatuses.find((status) => status.id === this.item.revision_status) || null;
    }

    get attachmentStatus() {
        return this.getAttachmentStatus(this.previewItem?.status || '');
    }

    get attachments() {
        return this.attachmentsStore.data;
    }

    set attachments(data: RentDocumentAttachment[]) {
        this.$set(this.attachmentsStore, 'data', data);
    }

    public isPermitted(arg: string) {
        return this.permissionCheck(`rents.documents.${arg}`);
    }

    public revisonSheduled() {
        this.scheduleRevisonDialog = false;
        this.fetchDetails();
        this.fetchAttachents();
    }

    public editDocument() {
        this.editedItem = _.cloneDeep(this.item);
        this.formDialog = true;
    }

    public chooseAttachmentToEdit() {
        this.formDialog = false;
        if (this.attachments.length === 1 && this.previewItem) {
            this.startEditingAttachment(this.previewItem);
        } else {
            this.attachmentSelectDialog = true;
        }
    }

    public attachmentToEditChoosen(attachment: RentDocumentAttachment) {
        this.attachmentSelectDialog = false;
        this.startEditingAttachment(attachment);
    }

    public itemSaved() {
        this.formDialog = false;
        this.attachments = [];
        this.fetchDetails();
        this.fetchAttachents();
    }

    public goToNextFollowing() {
        this.previewItem = this.item.next_following_attachment;
        this.pdfPreview.$el.scrollIntoView({ behavior: 'smooth' });
    }

    public startEditingAttachment(attachment: RentDocumentAttachment) {
        this.$set(this, 'attachmentItemData', _.cloneDeep(attachment));
        this.editAttachment = true;
        this.attachmentsForm = true;
    }

    public createAttachment() {
        this.attachmentItemData = {};
        this.editAttachment = false;
        this.attachmentsForm = true;
    }

    public cancelAttachmentForm() {
        this.attachmentItemData = {};
        this.editAttachment = false;
        this.attachmentsForm = false;
    }

    public onAttachmentSaved(data: any) {
        this.loadingAttachments = true;
        this.attachmentItemData = {};
        this.editAttachment = false;
        this.attachmentsForm = false;
        this.newPreviewItemId = data.data.id;
        this.fetchDetails();
    }

    public getAttachmentStatus(statusId: string) {
        return documentAttachmentStatuses.find((status) => status.id === statusId) || null;
    }

    public deleteAttachment(id: number) {
        httpClient
            .delete(`/api/v1/rents/documents/${this.item.id}/attachments/${id}`)
            .then(({ data }) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: `Pomyślnie usunięto dokument`,
                });
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.previewItem = null;
                this.fetchAttachents();
                this.fetchLogs();
            });
    }

    public setFetchParams() {
        this.fetchParams = {
            id: this.id,
            rent_id: this.rentId,
        };
    }

    public fetchCallback({ data }: ModuleShowPayload<RentDocument>) {
        if (!this.newPreviewItemId && data?.current_attachment) {
            this.previewItem = data.current_attachment;
        }
        this.fetchAttachents();
        this.fetchLogs();
    }

    public fetchAttachents() {
        this.loadingAttachments = true;

        httpClient.get(`api/v1/rents/documents/${this.id}/attachments`).then((res) => {
            this.attachments = res.data.data;
            if (this.newPreviewItemId) {
                this.previewItem = this.attachments.find((el) => el.id === this.newPreviewItemId) || null;
                this.newPreviewItemId = 0;
            }
            if (!this.previewItem && this.attachments.length > 0) {
                this.previewItem = this.attachments[0];
            }
            this.loadingAttachments = false;
        });
    }

    public fetchLogs() {
        this.$store.dispatch(rentDocumentLogsModule.actionsTypes.FETCH_DATA, { simple: false, id: this.id });
    }

    public created() {
        this.loadingAttachments = true;
        this.fetchDetails();
    }
}
