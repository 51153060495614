import {DataRequest} from '@/shared/types';

export class RentPropertyDataRequest extends DataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_id: number = 0;
    // tslint:disable-next-line:variable-name
    public rent_property_type_id: number = 0;
    public public: boolean = false;
    public value: string = '';
}
