
import { Component, Prop } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleState } from '@/shared/state/template/module-state';
import { rentDocumentsListFilters, rentDocumentsListHeaders } from '@/modules/rents/shared/config';
import { rentDocumentCategoriesModule, rentDocumentsModule } from '@/modules/rents/shared/state/state';
import { RentDocumentDataRequest } from '@/modules/rents/shared/requests/rent-document-data-request';
import ItemsTable from '@/shared/components/layout/list.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import RentDocumentForm from './form.vue';

import { callSuper } from '@/shared/helpers';
import { RentDocument } from '../../shared/models/documents/rent-document';
import ChipColor from '@/shared/components/elements/chip-color.vue';
import { documentRevisionStatuses, documentStatuses } from '@/shared/config/rent-documents';
import { FetchParams } from '@/shared/types';
import { logger } from '@/shared/services';
import { RentDocumentCategory } from '../../shared/models/documents/rent-document-category';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ItemsTable,
        RentDocumentForm,
        ChipColor,
        TooltipBtn,
    },
})
export default class RentDocumentsList extends ListComponent<RentDocument, RentDocumentDataRequest> {
    @Prop(Number) public rentId!: number;
    public props: ModuleProps = rentDocumentsModule.moduleProps;
    public headers = rentDocumentsListHeaders;
    public actionsTypes = rentDocumentsModule.actionsTypes;
    public mutationTypes = rentDocumentsModule.mutationsTypes;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public id = this.rentId;
    public store: ModuleState<RentDocument, RentDocumentDataRequest> = this.$store.state.rentsState.rentDocumentsState;
    public filters = rentDocumentsListFilters;

    public created() {
        this.fetchCategories();
    }

    public fetchCategories() {
        this.$store
            .dispatch(rentDocumentCategoriesModule.actionsTypes.FETCH_DATA, { simple: false })
            .then(({ data }) => {
                this.filters[2].data = data.map((category: RentDocumentCategory) => {
                    return { name: category.id, label: category.name, value: false };
                });
            });
    }

    public getStatus(statusId: string) {
        return documentStatuses.find((status) => status.id === statusId)!;
    }

    public getRevisionStatus(statusId: string) {
        return documentRevisionStatuses.find((status) => status.id === statusId)!;
    }

    public fetchData(callback?: (data: RentDocument[]) => void) {
        if (this.fetchAction) {
            this.selected = [];
            const params = this.parseParams(
                this.fetchParams ? { ...this.fetchParams, id: this.rentId } : ({ id: this.rentId } as FetchParams),
            );

            this.$store
                .dispatch(this.fetchAction, params)
                .catch((error) => {
                    logger.error(error);
                })
                .then((response) => {
                    if (response) {
                        if (callback) {
                            callback(response.data);
                        } else {
                            this.$emit('loaded', response.data);
                        }
                    }
                });
        }
    }

        public addNew() {
            this.formDialog = false;
            if (this.store.editedItem) {
                this.store.editedItem.rent_id = this.rentId;
                this.formDialog = true;
            }
        }
    }
